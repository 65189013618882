<template>
    <v-snackbar
        class="global-alert-notification-snackbar"
        v-model="show"
        :timeout="-1"
        color="info"
        location="top"
    >
        <span
            class="text-subtitle-1 font-weight-bold"
        >
            Website updated! Please reload your browser.
        </span>
        <template
            #actions
        >
            <v-btn
                color="rgb(var(--v-theme-on-secondary))"
                class="text-secondary"
                variant="flat"
                @click="router.go(0)"
            >
                Reload
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts" setup>
    import type { NuxtAppManifestMeta, NuxtAppManifest } from '#app';
    import { useTimer } from '~/composables';

    const router = useRouter();
    const serverMeatPath = '/_nuxt/builds/latest.json';
    const show = ref(false);
    const timer = useTimer(checkVersion, 600000);

    async function checkVersion() {
        let currentManifest: Nilish<NuxtAppManifest>;
        let serverManifest: Nilish<NuxtAppManifestMeta>;

        try {
            currentManifest = await getAppManifest();
            serverManifest = await $fetch<NuxtAppManifestMeta>(serverMeatPath);
        } catch (err) {}

        if (currentManifest && serverManifest && currentManifest.id !== serverManifest.id) {
            show.value = true;
        }
    }

    onBeforeMount(() => {
        timer.start();
    });

    onBeforeUnmount(() => {
        timer.destroy();
    });
</script>

<style lang="scss"></style>
